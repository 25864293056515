import styled from "styled-components"
import { CommonSubtitle, CommonTitle } from "../../common/Texts"


export const Wrapper = styled.div`
  width: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
`


export const Title = styled(CommonTitle)``

export const Subtitle = styled(CommonSubtitle)`
  font-size: 1em;
  font-weight: normal;
  &.mobile {
    padding: 0em 3em;
  }
`

